import React, { useContext, useEffect, useRef } from "react";
import axios from "axios";
import { navigate } from "gatsby";
import { useMixpanel } from 'gatsby-plugin-mixpanel'
import LogRocket from 'logrocket';
import { API_URL_V1, GOOGLE_CLIENT_ID, windowGlobal } from "../config";
import GlobalContext from "../context/GlobalContext";

export function GoogleButton() {
  const divRef = useRef(null);
  const mixpanel = useMixpanel()
  const { setSession } = useContext(GlobalContext);

  useEffect(() => {
    if (divRef.current && window.google) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID || "",
        login_uri: `${API_URL_V1}/auth/google`,
        ux_mode: "popup",
        callback(response) {
          axios.post(`${API_URL_V1}/auth/google`, response).then((res) => {
            mixpanel.identify(res.data.email);
            const name = `${res.data.firstName} ${res.data.lastName || ""}`;
            mixpanel.people.set({
              email: res.data.email,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              name,
            });
            LogRocket.identify(res.data.email, {
              name,
              email: res.data.email,
            });
            windowGlobal?.localStorage?.setItem(
              "@modstop/session",
              JSON.stringify(res.data)
            );
            setSession(res.data);
            navigate("/app/integrate");
          });
        },
      });
      window.google.accounts.id.renderButton(divRef.current, {
        theme: "outline",
        size: "larger",
        shape: "rectangular",
        type: "standard",
        text: "continue_with",
      });
    }
  }, []);
  return (
    <div ref={divRef}>
      <div
        id="g_id_onload"
        data-client_id={GOOGLE_CLIENT_ID}
        data-context="signup"
        data-ux_mode="popup"
        data-login_uri={`${API_URL_V1}/auth/google`}
        data-auto_prompt="false"
      ></div>

      <div
        className="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text="continue_with"
        data-size="large"
        data-logo_alignment="left"
      ></div>
    </div>
  );
}
